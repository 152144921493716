<script setup lang="ts">
import {
  SAlertType,
  SMainToolbar,
  SProgressCircular,
  SSelect,
  STextField,
  STextFieldType
} from 'sima-suite-components-vue3'
import { toTypedSchema } from '@vee-validate/rules'
import { useForm } from 'vee-validate'
import { useTranslator } from 'sima-suite-translator-vue3'
import { computed, onActivated, onMounted, ref, watchEffect } from 'vue'
import { useAppointmentStore } from '@/store'
import { createARelevantMessage } from '@/utils'
import { useRoute } from 'vue-router'

const { translate } = useTranslator()
const route = useRoute()
const appointmentStore = useAppointmentStore()
const isLoading = ref(false)

const image = new URL(`../../assets/step-2.svg`, import.meta.url).href

const isSingleBrand = computed(() => {
  return appointmentStore.workshopSelected.brands!.length === 1
})

const singleBrandName = computed(() => {
  return appointmentStore.vehicle.brand?.name
})

const isQueryBrandValid = computed(() => {
  const brand = appointmentStore.brands.find((b) => b.code === (route.query.brand as string))
  return !!brand
})

type FormFields = {
  brand: string
  model: string
  plate: string
  kilometers: number
}

const { errors, defineField, meta, setFieldValue } = useForm<FormFields>({
  validationSchema: toTypedSchema({
    brand: {
      required: true
    },
    model: {
      required: true,
      min: 1
    },
    plate: {
      required: true,
      min: 1
    },
    kilometers: {
      required: true,
      min_value: 1,
      min: 1,
      max: 6,
      regex: /^\d{1,6}$/
    }
  }),
  validateOnMount: false
})

const modelError = computed(() => {
  return errors.value.model?.replace('model', translate('AppointmentWizard.Steps.Step2.Model'))
})

const plateError = computed(() => {
  return errors.value.plate?.replace('plate', translate('AppointmentWizard.Steps.Step2.Plate'))
})

const kilometersError = computed(() => {
  return errors.value.kilometers?.replace(
    'kilometers',
    translate('AppointmentWizard.Steps.Step2.Kilometers')
  )
})

const [brand] = defineField('brand')
const [model] = defineField('model')
const [plate] = defineField('plate')
const [kilometers] = defineField('kilometers')

const setUrlQueryParams = () => {
  const brand = appointmentStore.brands.find((b) => b.code === (route.query.brand as string))
  if (brand !== undefined) {
    setFieldValue('brand', brand?.name ?? '')
  }
  if (route.query.model) {
    setFieldValue('model', (route.query.model as string) ?? '')
  }
  if (route.query.plate) {
    setFieldValue('plate', (route.query.plate as string) ?? '')
  }
  if (route.query.kilometers) {
    appointmentStore.vehicle.vin = (route.query.vin as string) ?? ''
  }
}

const brandsInCommon = computed(() => {
  const filterBrands = appointmentStore.brands
    .filter((brand) =>
      appointmentStore.workshopSelected.brands!.some((service) => service.code === brand.code)
    )
    .map((brand) => brand.name)

  return filterBrands.filter((brand) => brand !== undefined)
})

onActivated(() => {
  if (brandsInCommon.value.length) {
    setFieldValue('brand', brandsInCommon.value[0]!)
  }
})

onMounted(async () => {
  isLoading.value = true
  try {
    await appointmentStore.getBrands()
    if (brandsInCommon.value.length === 1) {
      appointmentStore.vehicle.brand = appointmentStore.brands.find(
        (brand) => brand.name === brandsInCommon.value[0]!
      )
    }
    if (brandsInCommon.value.length) {
      setFieldValue('brand', brandsInCommon.value[0]!)
    }
    setUrlQueryParams()
  } catch (error) {
    createARelevantMessage(translate('Error.Step.Vehicle'), SAlertType.ERROR)
  } finally {
    isLoading.value = false
  }
})

watchEffect(() => {
  appointmentStore.isVehicleFormValid = meta.value.valid
})

watchEffect(() => {
  appointmentStore.vehicle.brand = appointmentStore.brands.find((b) => b.name === brand.value)
  appointmentStore.vehicle.model = model.value
  appointmentStore.vehicle.plate = plate.value
  appointmentStore.vehicle.kilometers = Number(kilometers.value)
})

const avoidDecimalNumbers = (event: any) => {
  if (event.key === '.' || event.key === ',') {
    event.preventDefault()
  }
}
</script>

<template>
  <div class="flex h-full gap-spacing-m overflow-y-hidden p-spacing-s pt-spacing-s">
    <div class="hidden rounded-2xl border border-[#E0E0E0] px-spacing-l py-16 sm:block sm:w-3/5">
      <img :src="image" :alt="translate('AppointmentWizard.Steps.Step2.AltImage')" />
    </div>
    <div v-if="!isLoading" class="h-full w-full overflow-y-auto sm:w-2/5">
      <s-main-toolbar
        class="pb-spacing-s"
        :title="translate('AppointmentWizard.Steps.Step2.TitleForm')"
      />
      <form class="flex flex-col gap-spacing-s" @submit.prevent>
        <div v-if="isSingleBrand || isQueryBrandValid" class="flex flex-col">
          <span class="pb-1 text-xs text-color-neutral-300">
            {{ translate('AppointmentWizard.Steps.Step2.Brand') }}
          </span>
          <span> {{ singleBrandName }} </span>
        </div>
        <s-select
          v-else
          v-model:select="brand"
          :items="brandsInCommon"
          :label="translate('AppointmentWizard.Steps.Step2.Brand')"
        />
        <s-text-field
          v-model:value="model"
          :is-read-only="!!route.query.model"
          :type="STextFieldType.text"
          :label="translate('AppointmentWizard.Steps.Step2.Model')"
          :error-messages="modelError"
        />
        <s-text-field
          v-model:value="plate"
          :is-read-only="!!route.query.plate"
          :type="STextFieldType.text"
          :label="translate('AppointmentWizard.Steps.Step2.Plate')"
          :error-messages="plateError"
        />
        <s-text-field
          v-model:value="kilometers"
          :type="STextFieldType.number"
          :label="translate('AppointmentWizard.Steps.Step2.Kilometers')"
          :error-messages="kilometersError"
          @keydown="avoidDecimalNumbers"
        />
      </form>
    </div>
    <div v-else class="flex size-full items-center justify-center">
      <s-progress-circular />
    </div>
  </div>
</template>
