import { defineStore } from 'pinia'
import { State } from '@/store/appointment/types.ts'
import { createEmptyVehicle, createEmptyWorkshop } from '@/helpers/constructors.ts'
import { useHost, useAutogenerateClients } from '@/composables'
import { createWorkshopDate, getTeamId } from '@/utils'
import dayjs from '@/utils/dayjs.ts'
import { Service } from '@/types.ts'
import {
  AppointmentOnlineCreationRequest,
  CustomerRequest,
  IAvailableHourForAdviserDto,
  IWorkshopAvailabilityDto,
  ServiceRequest,
  VehicleRequest,
  WorkshopCampaignDto
} from 'online-customer-services-ts'
import { useTranslator } from 'sima-suite-translator-vue3'

const { language } = useTranslator()
const { getClient } = useAutogenerateClients()
const { id: hostId } = useHost()
export const useAppointmentStore = defineStore({
  id: 'appointmentStore',
  state: (): State => ({
    workshops: [],
    workshopSelected: createEmptyWorkshop(),
    brands: [],
    services: [],
    vehicle: createEmptyVehicle(),
    isVehicleFormValid: false,
    selectedServices: [],
    workshopAvailability: [],
    campaigns: [],
    availabilityCalls: 0,
    workshopAvailabilityDate: '',
    workshopAvailabilityRange: NaN,
    workshopAvailabilityAdviser: undefined,
    isContactFormValid: false,
    contactDetails: {
      name: '',
      email: '',
      mobilePhone: '',
      phone: ''
    },
    wasSentRequest: false
  }),
  actions: {
    async getWorkshops() {
      const workshops = getClient('workshops')
      this.workshops = await workshops.getMarketWorkshops(hostId.value)
    },
    async getBrands() {
      const brands = getClient('brands')
      this.brands = await brands.getMarketBrands(hostId.value)
    },
    async getVehicle() {
      const vehicles = getClient('vehicles')
      this.vehicle.vin = ''
      const { vin } = await vehicles.getVehicleByPlate3(this.vehicle.plate)
      this.vehicle.vin = vin
    },
    async getServices() {
      const services = getClient('services')
      this.services = await services.getMarketServices(hostId.value, language.value)
    },
    async getWorkshopAvailability(date: Date) {
      const availabilityClient = getClient('workshopAvailability')

      const abbreviation =
        this.vehicle.brand && this.vehicle.brand.code ? this.vehicle.brand.code : ''
      const fromDate = createWorkshopDate(
        dayjs(date.toISOString()).format('YYYY-MM-DD'),
        this.workshopSelected.startingWorkHour!
      )
      const toDate = createWorkshopDate(
        dayjs(date.toISOString()).format('YYYY-MM-DD'),
        this.workshopSelected.startingWorkHour!
      )
      const selectedDate = dayjs(date.toISOString()).isToday()
        ? dayjs(date.toISOString()).add(this.workshopSelected.daysOfMarginToRequest, 'day').toDate()
        : date

      const servicesSelectedIds = this.selectedServices.map((service: Service) => service.id)

      const { workshopAvailability, campaigns } =
        await availabilityClient.getWorkshopAvailability22(
          hostId.value,
          selectedDate,
          fromDate,
          toDate,
          this.workshopSelected.id,
          servicesSelectedIds,
          abbreviation,
          this.vehicle.vin,
          null,
          null
        )
      this.workshopAvailability = workshopAvailability!
      this.campaigns = campaigns || []
      this.availabilityCalls = this.availabilityCalls + 1
    },
    resetAvailability() {
      this.workshopAvailability = []
      this.availabilityCalls = 0
      this.workshopAvailabilityDate = ''
      this.workshopAvailabilityRange = NaN
      this.workshopAvailabilityAdviser = undefined
    },
    async createAppointment() {
      const appointmentRequestData = new AppointmentOnlineCreationRequest({
        adviserId: this.workshopAvailabilityAdviser!.adviserId,
        cultureName: language.value,
        customer: new CustomerRequest({
          customerId: undefined,
          email: this.contactDetails.email,
          mobile: this.contactDetails.mobilePhone,
          name: this.contactDetails.name,
          phone: this.contactDetails.phone
        }),
        services: this.selectedServices.map(
          (service: Service) =>
            new ServiceRequest({
              id: service.id,
              remarks: service.remarks
            })
        ),
        teamId: getTeamId(this.workshopAvailability, this.workshopAvailabilityDate),
        vehicle: new VehicleRequest({
          brandCode: this.vehicle.brand!.code,
          kms: this.vehicle.kilometers,
          model: this.vehicle.model,
          plate: this.vehicle.plate,
          vin: this.vehicle.vin === '' ? undefined : this.vehicle.vin
        }),
        when: createWorkshopDate(
          this.workshopAvailabilityAdviser!.adviserDate!,
          this.workshopAvailabilityAdviser!.adviserTime!
        ),
        workshopId: this.workshopSelected.id,
        campaigns: this.campaigns.map((campaign) => new WorkshopCampaignDto(campaign))
      })
      const appointmentsClient = getClient('appointmentsClient')

      this.wasSentRequest = true
      await appointmentsClient.createAppointment22(
        hostId.value,
        this.workshopSelected.simaId.toString(),
        appointmentRequestData
      )
    }
  },
  getters: {
    availableDays: (state): Date[] => {
      const availableDays = state.workshopAvailability.filter((day) => day.advisers!.length > 0)
      return availableDays.map((day) => {
        return new Date(dayjs(day.advisers![0].dateTime).format('YYYY-MM-DD'))
      })
    },
    availableRangeHours: (state): number[] => {
      const availableDay: IWorkshopAvailabilityDto | undefined = state.workshopAvailability.find(
        (day: IWorkshopAvailabilityDto) => {
          if (day.advisers?.length === 0) return false

          return day.advisers![0].adviserDate === state.workshopAvailabilityDate
        }
      )
      if (availableDay) {
        const availableAdviserHours: number[] = availableDay.advisers!.map(
          (adviser: IAvailableHourForAdviserDto) => {
            return Number(adviser.adviserTime!.split(':')[0])
          }
        )
        return [...new Set(availableAdviserHours)]
      }

      return []
    },
    availableAdvisers: (state): IAvailableHourForAdviserDto[] => {
      const currentDateAvailability = state.workshopAvailability.find((day) => {
        if (day.advisers?.length === 0) return false

        return day.advisers![0].adviserDate === state.workshopAvailabilityDate
      })
      if (currentDateAvailability) {
        return currentDateAvailability.advisers!.filter(
          (adviser) =>
            Number(adviser.adviserTime!.split(':')[0]) === state.workshopAvailabilityRange
        )
      }

      return []
    }
  }
})
